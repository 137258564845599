var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout-banner",
    [
      _c(
        "b-row",
        { staticClass: "px-2 pb-5 vh100" },
        [
          _c(
            "b-col",
            { staticClass: "content-center", attrs: { cols: "12" } },
            [
              _c("FormIdentificacion", {
                attrs: { esPagoWeb: true, title: "Realiza tu pago" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }